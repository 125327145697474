<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>

      <div class="wrapper wrapper-content">
        <div class="row wrapper border-bottom white-bg page-heading">
          <div class="col-lg-12">
            <hr class="bg-primary">
              <h5>Manuales de Usuario EcoNet</h5>
            <hr class="bg-primary">

              <div class="row justify-content-center mt-4">
                <div class="col-xl-2 col-lg-4 col-md-12">
                  <div class="card">
                    <div class="card-title">
                      <h1 class="text-block mt-3 mb-8 text-center" style="width: 12rem;">
                        <img src="/resources/images/guide.png" class="card-img-top" alt="Manual">
                      </h1>
                    </div>
                    <div class="card-body text-center">
                      <h5 class="text-block text-secondary">Manual de Usuario EcoNet</h5>
                      <a href="https://xd.adobe.com/view/27fc00bf-fa21-46c8-ae37-1e7533e059e6-72af/?fullscreen" target="_blank" class="btn btn-primary">
                        Descargar <b-icon icon="cloud-arrow-down-fill" />
                      </a>
                      <!-- <a href="/resources/manual/Planeación de Servicios.pdf" target="_blank" class="btn btn-primary">
                        Descargar <b-icon icon="cloud-arrow-down-fill" />
                      </a> -->
                    </div>
                  </div>
                </div>

                <!-- <div class="col-xl-2 col-lg-4 col-md-12">
                  <div class="card">
                    <div class="card-title">
                      <h1 class="text-block mt-3 mb-8 text-center" style="width: 12rem;">
                        <img src="/resources/images/guide.png" class="card-img-top" alt="Manual">
                      </h1>
                    </div>
                    <div class="card-body text-center">
                      <h5 class="text-block text-secondary">Manual de Recepción de Servicios</h5>
                      <a href="/resources/manual/Planeación de Servicios.pdf" target="_blank" class="btn btn-primary">
                        Descargar <b-icon icon="cloud-arrow-down-fill" />
                      </a>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-xl-2 col-lg-4 col-md-12">
                  <div class="card">
                    <div class="card-title">
                      <h1 class="text-block mt-3 mb-8 text-center" style="width: 12rem;">
                        <img src="/resources/images/guide.png" class="card-img-top" alt="Manual">
                      </h1>
                    </div>
                    <div class="card-body text-center">
                      <h5 class="text-block text-secondary">Manual de Descarga de Materiales</h5>
                      <a href="/resources/manual/Planeación de Servicios.pdf" target="_blank" class="btn btn-primary">
                        Descargar <b-icon icon="cloud-arrow-down-fill" />
                      </a>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-xl-2 col-lg-4 col-md-12">
                  <div class="card">
                    <div class="card-title">
                      <h1 class="text-block mt-3 mb-8 text-center" style="width: 12rem;">
                        <img src="/resources/images/guide.png" class="card-img-top" alt="Manual">
                      </h1>
                    </div>
                    <div class="card-body text-center">
                      <h5 class="text-block text-secondary">Manual Contabilización del Servicio</h5>
                      <a href="/resources/manual/Planeación de Servicios.pdf" target="_blank" class="btn btn-primary">
                        Descargar <b-icon icon="cloud-arrow-down-fill" />
                      </a>
                    </div>
                  </div>
                </div> -->
              </div>

              <!-- <hr class="bg-primary">
                <h5>Manual de Vigilancia</h5>
              <hr class="bg-primary">
              <div class="row justify-content-center mt-4">
                <div class="col-xl-2 col-lg-4 col-md-12">
                  <div class="card">
                    <div class="card-title">
                      <h1 class="text-block mt-3 mb-8 text-center" style="width: 12rem;">
                        <img src="/resources/images/guide.png" class="card-img-top" alt="Manual">
                      </h1>
                    </div>
                    <div class="card-body text-center">
                      <h5 class="text-block text-secondary">Manual de Vigilancia</h5>
                      <a href="/resources/manual/Planeación de Servicios.pdf" target="_blank" class="btn btn-primary">
                        Descargar <b-icon icon="cloud-arrow-down-fill" />
                      </a>
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- <hr class="bg-primary">
                <h5>Manual de Operaciones</h5>
              <hr class="bg-primary">
              <div class="row justify-content-center mt-4">
                <div class="col-xl-2 col-lg-4 col-md-12">
                  <div class="card">
                    <div class="card-title">
                      <h1 class="text-block mt-3 mb-8 text-center" style="width: 12rem;">
                        <img src="/resources/images/guide.png" class="card-img-top" alt="Manual">
                      </h1>
                    </div>
                    <div class="card-body text-center">
                      <h5 class="text-block text-secondary">Manual de Vigilancia</h5>
                      <a href="/resources/manual/Planeación de Servicios.pdf" target="_blank" class="btn btn-primary">
                        Descargar <b-icon icon="cloud-arrow-down-fill" />
                      </a>
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- <hr class="bg-primary">
                <h5>Manual de Laboratorio</h5>
              <hr class="bg-primary">
              <div class="row justify-content-center mt-4">
                <div class="col-xl-2 col-lg-4 col-md-12">
                  <div class="card">
                    <div class="card-title">
                      <h1 class="text-block mt-3 mb-8 text-center" style="width: 12rem;">
                        <img src="/resources/images/guide.png" class="card-img-top" alt="Manual">
                      </h1>
                    </div>
                    <div class="card-body text-center">
                      <h5 class="text-block text-secondary">Manual de Laboratorio</h5>
                      <a href="/resources/manual/Planeación de Servicios.pdf" target="_blank" class="btn btn-primary">
                        Descargar <b-icon icon="cloud-arrow-down-fill" />
                      </a>
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- <hr class="bg-primary">
                <h5>Manual de Administración</h5>
              <hr class="bg-primary">
              <div class="row justify-content-center mt-4">
                <div class="col-xl-2 col-lg-4 col-md-12">
                  <div class="card">
                    <div class="card-title">
                      <h1 class="text-block mt-3 mb-8 text-center" style="width: 12rem;">
                        <img src="/resources/images/guide.png" class="card-img-top" alt="Manual">
                      </h1>
                    </div>
                    <div class="card-body text-center">
                      <h5 class="text-block text-secondary">Manual de Administración</h5>
                        <a href="/resources/manual/Planeación de Servicios.pdf" target="_blank" class="btn btn-primary">
                          Descargar <b-icon icon="cloud-arrow-down-fill" />
                        </a>
                    </div>
                  </div>
                </div>
              </div> -->

          </div>
        </div>
      </div>

  </div>
</template>

<script>
export default {
  // name: 'menuExcelForm',
  components: {
  },
  computed: {
  },
  data() {
    return {
      // Contants
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Manuales',
          aIcon: '',
        },
      ],
      resource: '',
      resourceName: 'Manuales',
      searchInput: null,
      // Constants
      // actualService: {},
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.border-bottom {
  border-width:3px !important;
}
</style>
